@import 'src/assets/styles/table.scss';

.table {
	min-width: 780px;
}

.row {
	@extend %row;
	grid-template-columns: 5% 4fr 3fr repeat(3, minmax(120px, 1.5fr)) 120px;
}
@import "src/assets/styles/templates";

.wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;

	height: max-content;

	padding: 20px;
	border-radius: 5px;

	background: var(--c_white-all);
	border: 1px solid var(--c_light);
}

.mapWrapper {
	overflow: hidden;
}

.editorWrapper {
	width: 100%;
}

@media screen and (max-width: 1600px) {
	.wrapper {
		@include flex-col-gap(20px);
	}

	.emptyItem {
		display: none;
	}
}
