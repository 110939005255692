@import "src/assets/styles/templates";

// all styles
.wrapper {
  @include flex-col-gap(8px);
  position: relative;
  width: 100%;
}

.label {
  color: var(--c_black);
  font-size: 14px;
  line-height: 20px;
}

.subText {
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px;
  color: var(--c_grey);
}

.disabled {
  opacity: .5;
  pointer-events: none;
}

.validation {
  border: 1px solid var(--c_red) !important;
}

.textArea, .input {
  width: 100%;

  color: var(--c_black);
  border: 1px solid var(--c_grey);
  background-color: var(--c_white-all);
  border-radius: $br_default;

  font-size: 14px;
  font-weight: 400;

  &::placeholder {
    color: var(--c_dark);
  }

  &:not(:placeholder-shown) {
    border-color: var(--c_dark) !important;
    color: var(--c_black);
  }

  &:focus {
    border-color: var(--c_blue) !important;
  }
}

// custom input styles
.input {
  height: 48px;
  padding-left: 15px;
  padding-right: 15px;
}

.passwordEye {
  position: absolute;
  right: 16px;
  top: 43px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  cursor: pointer;
}

// custom textArea styles
.textArea {
  padding: 15px;
  height: 100px;
}

.notResizable {
  resize: none;
}