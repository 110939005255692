@import 'src/assets/styles/templates.scss';

.statusCards {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px;
}
.form {
  @include flex-col-gap(20px);
  justify-content: space-between;
  height: 100%;
}
.btns {
  @include flex-gap(20px);
  align-items: center;
}
.delete {
  width: 48px;
  height: 48px;
  margin-left: auto;
  padding: 0;
  border-width: 2px;

  svg {
    width: 24px;
    height: 24px;
  }
  svg path {
    fill: var(--c_red);
  }

  &:hover svg path {
    fill: var(--c_white-all);;
  }
}
.fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 32px;
  column-gap: 20px;

  color: var(--c_white-text);
  border: $b_border;
  border-radius: $br_default;
  background-color: var(--c_white-all);

  padding: 20px;
}
.field {
  align-self: flex-start;
}
.chips {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 18px;

  .chip {
    display: flex;
    align-items: center;
    gap: 12px;

    padding: 14px 20px;

    border-radius: $br_default;
    background-color: var(--c_light);
    
    svg {
      cursor: pointer;
    }
  }
}

.item {
  @extend %section;
  align-items: flex-start;
  width: 385px;

  border: $b_border;

  .image {
    max-height: 130px;
    max-width: 280px;
    border-radius: 8px;
    object-fit: contain;
  }
  .title {
    margin-top: 20px;
    font-size: 16px;

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .description {
    height: 35px;
    margin-top: 8px;
    color: var(--c_dark);

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .categories {
    @include flex-gap(20px);
    align-items: center;
    margin-top: 20px;
  }
  .category {
    @include flex-gap(8px);
    align-items: center;

    span {
      color: var(--c_dark);
    }
  }
  .btns {
    @include flex-gap(12px);
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }
  .edit {
    height: 36px;
    width: 100%;
  }
  .delete {
    padding: 8px;
    background-color: var(--c_red-uncative);
    border: none;

    svg path {
      fill: var(--c_red);
    }
  }
}
