.loaderPage {
  display: flex;
  flex-direction: column;

  margin: 0 40px;
  margin-bottom: 50px;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  // transform: translateX(-120px);
}