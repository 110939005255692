@import "src/assets/styles/templates";

.item {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  word-wrap: anywhere;

  span {
    color: var(--c_grey);
  }

  a {
    color: var(--c_blue);
  }
}

.change {
  background: var(--c_light);
}

.add {
  background: var(--c_blue-uncative);
}

.remove {
  background: var(--c_danger-unactive);
}