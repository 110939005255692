@import "src/assets/styles/templates";

.wrapper {
  @include flex-col-gap(60px);

  max-width: 420px;
  width: 100%;

  margin: 40px 70px;
}

.content {
  @include flex-col-gap(20px);

  .title {
    color: var(--c_black);
    font-size: 24px;
    font-weight: 600;
  }

  .description {
    color: var(--c_dark);
    font-size: 14px;
    line-height: 20px;
  }

  .button {
    width: max-content;
  }
}