@import 'src/assets/styles/templates.scss';

.item {
  @extend %section;
  align-items: flex-start;
  width: 385px;

  border: $b_border;

  .imageWrapper {
    height: 130px;
  }
  .image {
    max-height: 130px;
    max-width: 280px;
    border-radius: 8px;
    object-fit: contain;
  }
  .title {
    margin-top: 20px;
    font-size: 16px;

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .description {
    height: 35px;
    margin-top: 8px;
    color: var(--c_dark);

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .btns {
    @include flex-gap(12px);
    align-items: center;
    width: 100%;
    margin-top: 40px;
  }
  .edit {
    height: 36px;
    width: 100%;
  }
  .delete {
    padding: 8px;
    background-color: var(--c_red-uncative);
    border: none;

    svg path {
      fill: var(--c_red);
    }
  }
}
