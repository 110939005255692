:root {
  --c_white-all: #ffffff;
  --c_white-text: #ffffff;
  --c_light: #f2f2f2;
  --c_border: #d3dde0; // хз что это
  --c_green: #1E9238;
  --c_aqua: #3bbcd4;
  --c_blue: #1991ff;
  --c_blue-uncative: #f5f9ff;
  --c_red: #ff3a30;
  --c_danger-unactive: #FFECEB;
  --c_red-uncative: #ffeceb;
  --c_grey: #b4b4b9;
  --c_dark: #5d5f69;
  --c_background: #FAFAFA;
  --c_black: #272937;
  --с_app-bg: #FAFAFA;
  --c_skeleton-gradient: rgba(255, 255, 255, 0.5);

  // new colors
  --black-default: #070707;
  --black-6: rgba(7, 7, 7, 0.06);
  --black-20: rgba(7, 7, 7, 0.2);
  --black-60: rgba(0, 0, 0, 0.6);

  --white-default: #fff;
  --white-14: rgba(255, 255, 255, 0.14);
  --white-20: rgba(255, 255, 255, 0.2);
  --white-50: rgba(255, 255, 255, 0.5);
  --white-80: rgba(255, 255, 255, 0.8);

  --blue-default: #1362ff;
  --blue-extralight: #f0f0f6;
  --blue-dark: #0d47ba;
  --blue-40: rgba(240, 240, 246, 0.4);

  --grey-default: #7a7a7a;
  --grey-light: #c7c7d1;
  --grey-dark: #404041;

  --red: #d3514b;
  --red_dark: #973733;
}