@import 'src/assets/styles/templates.scss';

.updatePage, .createPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px;
}

.form {
  @include flex-col-gap(20px);
  justify-content: space-between;
  height: 100%;
}

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 40px;
  column-gap: 20px;

  color: var(--c_white-text);
  border: $b_border;
  border-radius: $br_default;
  background-color: var(--c_white-all);

  padding: 20px;
}
.item {
  align-self: flex-start;
  word-break: break-all;
}
.btns {
  @include flex-gap(20px);
  align-items: center;
}
.fields {

}
.delete {
  width: 48px;
  height: 48px;
  margin-left: auto;
  padding: 0;
  border-width: 2px;

  svg {
    width: 24px;
    height: 24px;
  }
  svg path {
    fill: var(--c_red);
  }

  &:hover svg path {
    fill: var(--c_white-all);;
  }
}

