@import 'src/assets/styles/templates.scss';
@import 'src/assets/styles/table.scss';

.table {
	min-width: 570px;
}

.row {
	@extend %row;
	grid-template-columns: 5% minmax(68px, 11%) 1fr 25% 120px;
}
