@import 'src/assets/styles/templates.scss';
@import 'src/assets/styles/table.scss';

.table {
	min-width: 880px;
}
.null, .true, .false {
	border-radius: 50%;
	height: 8px;
	width: 8px;
	max-width: 8px !important;
	min-width: 8px;
}
.null {
	background: #C7C7D1;
}
.false {
	background: #FAAD14;
}
.true {
	background: #52C41A;
}
.status {
	display: flex;
	align-items: center;
	gap: 8px;
}
.row {
	@extend %row;
	grid-template-columns: minmax(68px, 10%) 1fr 0.8fr 12% 14% 160px 120px 120px 50px;
}
.additional {
	display: flex;
	flex-direction: column;
	gap: 24px;
	button {
		width: fit-content;
	}
}
