@import "src/assets/styles/templates";

.phoneField {
  @include flex-col-gap(8px);
}

.phoneFieldWrapper {
  @include flex-gap(10px);
  align-items: center;
}

.prefixWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 88px;
  height: 48px;

  border-radius: 8px;
  background: var(--c_light);
}

.prefix {
  color: var(--c_black);
  line-height: 20px
}

.label{
  color: var(--c_black);
  line-height: 20px;
}