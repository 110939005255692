@import 'src/assets/styles/templates';

.documents {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px;
}
.aside {
  @include flex-col-gap(12px);
  width: calc(50% - 60px);

  .list {
    @include flex-col-gap(12px);
    margin-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--c_light);
  }
}
.title {
  font-size: 16px;
  font-weight: 500;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.item {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  span {
    color: var(--c_grey);
  }
}
.addDoc {
  width: fit-content;
}
.file{
	path{
		stroke: #1991FF;
	}
	margin-right: 8px;
}
.filegroup{
	display: flex;
}

.container {
  @extend %section;
  flex-direction: row;
  gap: 20px;

  padding: 20px;

  border: $b_border;
}
.left {
  @include flex-col-gap(24px);

  width: calc(50% - 20px);
}
.block {
  @include flex-col-gap(24px);

  .list {
    @include flex-col-gap(12px);
  }
}
.btns {
  display: flex;
  gap: 20px;
  margin-top: auto;
}

.size{
	color: var(--c_grey);
}

.btns {
  display: flex;
  gap: 24px;

  svg {
    cursor: pointer;
  }
}

.docWrapper {
	display: flex;
	flex-direction: column;
	gap: 4px;
	span {
		display: flex;
		gap: 8px;
		align-items: center;
		 p {
			 width: 8px;
			 height: 8px;
			 border-radius: 100%;
			 &.notChecked {
				 background-color: #B4B4B9;
			 }

			 &.checked {
				 background-color: #1991FF;
			 }

			 &.downloaded {
				 background-color: #1E9238;
			 }
		}
  }
}

.doc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  padding: 16px;

  border-radius: $br_default;
  border: 1px solid var(--c_grey);

  .btns {
    gap: 10px;
  }
}

.form {
  @include flex-col-gap(24px);

  .btns {
    gap: 10px;

    .cancel {
      border-width: 1px;
    }
  }
}
