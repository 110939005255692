.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 40px;
}
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0;
}
.backLink {
  align-self: flex-start;
	cursor: pointer;
  color: var(--c_blue);

  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
