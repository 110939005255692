@import 'src/assets/styles/templates.scss';
@import 'src/assets/styles/table.scss';

.table {
	min-width: 850px;
}

.row {
	@extend %row;
	grid-template-columns: 5% 40% 40% 220px;
}
