@import 'src/assets/styles/templates.scss';
@import 'src/assets/styles/table.scss';

.table {
	min-width: 880px;
}

.row {
	@extend %row;
	grid-template-columns: minmax(68px, 10%) 1fr 1fr 15% 14% 120px;
}

.approved {
	color: var(--c_blue);
}

.notApproved {
	color: var(--c_danger);
}
