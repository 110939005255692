@import 'src/assets/styles/templates.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;

  position: relative;

  padding-top: 40px;
}

.btns {
  display: flex;
  gap: 20px;
}

.header{
  @include flex-col-gap(12px);
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
}

.backLink {
  align-self: flex-start;
  color: var(--c_blue);
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.disabled {
  opacity: .5;
  pointer-events: none;
}