.notFound {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;

  h1 {
    margin-top: 60px;
    font-size: 24px;
    font-weight: bold;
  }
  p {
    margin-top: 20px;
    color: var(--c_dark);
  }
  a {
    margin-top: 20px;
  }
}