.auth {
  display: flex;
  flex-direction: column;

  width: 650px;

  margin: auto;
  padding: 60px;

  background-color: var(--c_white-all);
  border: 1px solid var(--c_light);
  border-radius: 12px;

  transform: translateX(-115px);
}
.field {
  margin-top: 24px;
}
.title {
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 60px;
}
.btn {
  margin-top: 48px;
}
