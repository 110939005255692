@import 'src/assets/styles/templates.scss';
@import 'src/assets/styles/table.scss';

.table {
	min-width: 750px;
}

.row {
	@extend %row;
	grid-template-columns: minmax(68px, 11%) 15% 1fr 15% 120px 120px;
}

.switchWrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.time {
	color: var(--c_dark);
}
