// all styles
.checkbox, .radio {
  cursor: pointer;
  color: var(--c_dark);
  margin-bottom: 0;

  input {
    display: none;
  }

  span {
    display: flex;
    align-items: flex-start;

    line-height: 20px;
    color: var(--c_dark);
    font-size: 14px;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    &::before {
      content: '';
      display: block;
      box-sizing: border-box;

      width: 18px;
      min-width: 18px;
      height: 18px;

      border-radius: 4px;
      border: 1px solid var(--c_dark);
      margin-right: 10px;

      transition: all .2s linear;
    }
  }
}

// custom checkbox styles
.checkbox {

  input:checked + span::before {
    border: 1px solid var(--c_blue);
    background-color: var(--c_blue);
    background-image: url('../../../assets/icons/form/ic_check.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px;
  }

  span::before {
    background-position: center;
    border-radius: 4px;
  }
}

// custom radio styles
.radio {
  input:checked + span::before {
    border: 5px solid var(--c_blue);
    background-image: none;
    background-color: var(--c_white-text);
  }

  span::before {
    border-radius: 50%;
  }
}