@import "src/assets/styles/templates";

.wrapper {
  @include flex-col-gap(8px);
  width: 100%;
}

.editorWrapper {
  border: 1px solid var(--c_grey);
  border-radius: $br_default;

  display: flex;
  justify-content: center;

  width: 100%;
  max-height: 900px;
  overflow-y: auto;
}

.label {
  color: var(--c_black);
  font-size: 14px;
  line-height: 20px;
}