@import "src/assets/styles/templates";

.switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
	width: 100%;

  span {
    color: #FFFFFF;
    margin: 0;
  }
}

// popup
.popup {
  @include flex-col-gap(24px);
  justify-content: center;
  padding: 20px;

  border-radius: 5px;
  border: 1px solid #B4B4B9;

  min-height: 189px;
  max-width: 576px;
  width: 100%;
}

.content {
  @include flex-col-gap(8px);
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  color: #272937;
}

.description {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #5D5F69;
}

.buttons {
  @include flex-gap(20px);
  align-self: flex-end;
}
