@import 'src/assets/styles/templates';

%row {
  display: grid;
  grid-auto-flow: column;
  min-height: 63px;
  padding: 0 10px;
  transition: background-color ease-in-out 0.15s;

  & .update {
    cursor: pointer;
  }

  // table head
  div:nth-child(1) > & {
    color: var(--c_white-text);

    // table cell
    & > div {
      @extend %cell;
    }
  }

  // table body
  div:nth-child(2) > & {
    background-color: var(--c_background);
    cursor: pointer;
    font-weight: 400;

    // table cell
    & div {
      @extend %cell;
    }

    &:nth-child(2n-1) {
      background-color: var(--c_white-all);
    }

    &:hover {
      background-color: var(--c_light);
    }
  }

  div:nth-child(2) > &:not(a) {
    cursor: default;
  }

  &:last-child {
    border-bottom: none;
  }
}

%cell {
	margin-top: auto;
	margin-bottom: auto;

  padding: 4px 10px 4px 10px;

	text-align: left;
  font-weight: 500;
  font-size: 16px;

  span {
    font-size: 16px;
    font-weight: 500;
  }
}

.table__row {
  @extend %row;
}

.update-text,
.delete-text {
  font-size: 16px;
}
