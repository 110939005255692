@import 'src/assets/styles/templates';

.list {
  margin-top: 18px;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;
}

.extra {
  display: inline-block;
  margin-top: 8px;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--c_grey);
}

.item {
  @include flex-gap(12px);

  padding: 14px 20px;

  background-color: var(--c_light);
  border-radius: $br_default;

  .title {
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
    color: var(--c_black);
  }

  .delete {
    width: 20px;
    height: 20px;

    cursor: pointer;
    background-color: transparent;
  }
}

.dropdownIndicator {
  padding: 14px;

  svg {
    width: 18px;
    height: 18px;
  }
}
