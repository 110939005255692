@import 'src/assets/styles/templates';

.calendar {
  position: absolute;
  bottom: 13px;
  right: 10px;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  path {
    pointer-events: none;
  }
}

.react-datepicker {
  max-width: 378px;
  width: 100vw;

  border-radius: 4px;
  border: 1px solid var(--c_light);
  background-color: var(--c_white-all);
  box-shadow: -2px 0px 8px -5px rgba(34, 60, 100, 0.6);
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  border: none;
  padding: 0;

  background-color: inherit;
}

.react-datepicker__tab-loop {
  position: relative;
}

.react-datepicker__navigation {
  top: 20px;
  margin: 0 20px;
}

.react-datepicker__month-container {
  width: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
}

.react-datepicker__current-month, .react-datepicker__year-read-view, .react-datepicker__year-read-view--selected-year {
  color: var(--c_black);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding: 20px 0;
}

.react-datepicker__year-read-view--down-arrow {
  display: none;
}

.react-datepicker__day-names {
  margin: 0;
  padding: 0 20px;

  border-top: 1px solid var(--c_light);
}

.react-datepicker__month {
  padding: 0 20px 20px 20px;
  margin: 0;
}

.react-datepicker__day, .react-datepicker__day-name {
  height: 48px;
  width: 48px;

  margin: 0;
  padding: 14px 0;

  border-radius: 5px;

  color: var(--c_black);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.react-datepicker__day:hover {
  background-color: var(--c_light) !important;
}

.react-datepicker__day--keyboard-selected {
  background: var(--c_blue);
  color: var(--c_white-text)
}

.react-datepicker__day--outside-month {
  color: var(--c_grey);
}

.react-datepicker__year-read-view {
  visibility: unset !important;
}

.react-datepicker-wrapper {
  height: 0;
  overflow: hidden;
  margin-top: 0 !important;
  position: absolute;
}

.react-datepicker__year-dropdown-container {
  position: relative;
}

.react-datepicker__year-dropdown {
  position: absolute;
  left: -80px;
  top: 50px;
  height: 150px;
  width: 150px;
  background-color: var(--c_white-all);
}

.react-datepicker__year-option:hover{
  background-color: var(--c_light);
}