@font-face {
  font-family: 'Inter';
  src: url('./Inter-SemiBold.woff2') format('woff2'),
  url('./Inter-SemiBold.woff') format('woff'),
  url('./Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Medium.woff2') format('woff2'),
  url('./Inter-Medium.woff') format('woff'),
  url('./Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Regular.woff2') format('woff2'),
  url('./Inter-Regular.woff') format('woff'),
  url('./Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}