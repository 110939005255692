@import 'src/assets/styles/templates.scss';

.wrapper {
  display: flex;
  gap: 4px;

  width: fit-content;

  margin-top: 20px;
  padding: 4px;

  border: $b_border;
  border-radius: $br_default;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  
  padding: 0;

  background-color: var(--c_white-all);
  color: var(--c_black);
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: var(--c_blue-uncative);
  }
}
.active {
  @extend .button;
  background-color: var(--c_blue);
  color: var(--c_white-text);

  &:hover {
    background-color: var(--c_blue);
  }
}

.buttonPrevious,
.buttonNext {
  @extend .button;

  border: none;

  &:hover svg path {
    stroke: var(--c_blue);
  }

  &:disabled {
    background-color: var(--c_white-all);
    cursor: default;

    &:hover path,
    path {
      stroke: var(--c_dark);
    }
  }
}
.dots {
  display: flex;
  width: 11px;
  margin: auto 20px auto 10px;
  color: var(--black);
  font-weight: 600;
  font-size: 18px;
}