@import 'src/assets/styles/templates.scss';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.content {
  position: relative;
  z-index: 10;

  background-color: var(--c_white-all);
  border-radius: $br_default;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}