@import "src/assets/styles/templates";

.popup {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1000;
}

.overlay {  
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;
  //padding: 100px 0;
  
  background-color: rgba(39, 41, 55, .5);
  overflow-y: scroll;
}

.content {
  position: relative;

  background-color: var(--c_white-all);
  border-radius: 5px;
  -webkit-box-shadow: 0 0 12px 0 rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0 0 12px 0 rgba(34, 60, 80, 0.2);
  box-shadow: 0 0 12px 0 rgba(34, 60, 80, 0.2);

  z-index: 100;

  @media (max-width: $m_phone) {
    margin: 130px 30px 100px;
  }
}