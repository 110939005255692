@import 'src/assets/styles/templates.scss';

.wrapper {
  @extend %flex-col;
  align-items: flex-start;
  padding: 40px;
  position: relative;
}
.title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}
.description {
  margin-top: 12px;
  color: var(--c_dark);
}
.logo {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  width: 50vw;
  height: 50vh;

  margin: auto;

  path {
    fill: var(--c_light);
  }
}