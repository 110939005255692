@import 'src/assets/styles/templates.scss';

.item {
  @extend %section;
  align-items: flex-start;
  width: 385px;

  border: $b_border;

  .title {
    font-size: 16px;

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .description {
    height: 35px;
    margin-top: 8px;
    color: var(--c_dark);

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .btns {
    @include flex-gap(12px);
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }
  .categories {
    @include flex-gap(20px);
    align-items: center;
    margin-top: 20px;
  }
  .category {
    @include flex-gap(8px);
    align-items: center;

    span {
      color: var(--c_dark);
    }
  }
  .edit {
    height: 36px;
    width: 100%;
  }
  .delete {
    padding: 8px;
    background-color: var(--c_red-uncative);
    border: none;

    svg path {
      fill: var(--c_red);
    }
  }
}
