@import 'src/assets/styles/table';

.sidebar {
  display: flex;
  flex-direction: column;

  position: fixed;
  left: 0;
  z-index: 1;

  width: 230px;
  height: 100vh;

  padding: 8px;

  background-color: var(--c_black);
}

.logo {
  display: block;
  margin: 16px 0 0 12px;
}

.navigation {
  @include flex-col-gap(24px);
  margin-top: 24px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 2px;

    svg {
      transition: .3s ease-in-out;

      path {
        transition: .15s ease-in-out;
      }
    }

    .title {
      margin-left: 8px;
      color: var(--c_dark);
      font-size: 14px;
      transition: .15s ease-in-out;
    }

    &:hover {
      .title {
        color: var(--c_white-text);
      }

      svg path {
        stroke: var(--c_white-text);
      }
    }
  }

  .accordion[aria-expanded=true] {
    .header svg {
      transform: rotateX(180deg);
    }
  }

  .globalLink {
    color: var(--c_dark);
    font-size: 14px;
    margin-left: 8px;
    transition: .15s ease-in-out;

    &:hover {
      color: var(--c_white-text);
    }
  }

  .links {
    @include flex-col-gap(2px);
    margin-top: 8px;
  }

  .link a {
    display: block;

    padding: 8px;

    color: var(--c_grey);
    border-radius: 4px;

    font-size: 14px;

    transition: .15s ease-in-out;

    &:hover {
      color: var(--c_white-text);
    }
  }

  .activeLink a {
    background-color: rgba(25, 145, 255, .14);
    color: var(--c_blue) !important;
  }
}

.bottom {
  margin-top: auto;
  padding: 12px;

  span {
    color: var(--c_dark);
  }

  strong {
    color: var(--c_white-text);
    font-size: 16px;
  }

  .role {
    display: block;
    margin-top: 4px;
  }

  .exit {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 36px;

    color: var(--c_dark);
    border: 1px solid var(--c_dark);
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;

    margin-top: 20px;

    transition: .15s ease-in-out;
  }
}
