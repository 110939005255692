@import "src/assets/styles/templates";

.selectWrapper {
  @include flex-col-gap(8px);
  width: 100%;

  label {
    color: var(--c_black);
    line-height: 20px;
  }
}