@import 'src/assets/styles/templates.scss';
@import 'src/assets/styles/table.scss';

.table {
	min-width: 1020px;
}

.row {
	@extend %row;
	grid-template-columns: minmax(68px, 10%) 1fr 1fr 15% 10% 15% 120px;
}

.approved {
	color: var(--c_blue);
}

.notApproved {
	color: var(--c_danger);
}

.approveItem {
	position: absolute;
	width: 200px;
	right: 20px;
}

.propertiesList {
	display: flex;
	flex-direction: column;

	position: relative;

	border: $b_border;
	border-radius: $br_default;
	color: var(--c_white-text);

	padding: 20px;

	.user {
		margin-bottom: 40px;
		font-weight: bold;
	}

	.answerItem {
		display: flex;
		gap: 10px;
		line-height: 2;
	}

	.question {
		font-weight: bold;
	}
}

.subList {
	display: flex;
	align-items: center;
	margin: 20px;

	& > * {
		border-radius: 23px;
		background-color: #f7f8fd;
		padding: 10px;
		border: 1px solid #8692A6;
		color: #8692A6;
	}

	& > *:not(:last-child) {
		margin-right: 10px;
	}
}

.html {
	display: block;
	margin-top: 10px;

	ul {
		list-style: disc;
	}

	ol {
		list-style: decimal;
	}

	ul, ol {
		padding-left: 20px;
	}
}
