@import 'src/assets/styles/templates.scss';
@import 'src/assets/styles/table.scss';

.table {
	min-width: 880px;
}

.row {
	@extend %row;
	grid-template-columns: minmax(68px, 10%) 1fr 1fr 0.7fr 0.7fr 0.7fr 300px;
}

.dateRangeAndLink {
	display: flex;
	align-items: center;
	justify-content: space-between !important;
}

.approved {
	color: var(--c_blue);
}

.notApproved {
	color: var(--c_red);
}

.emptyPosition {
	font-weight: 500;
	line-height: 20px;
	color: var(--c_grey);
}
