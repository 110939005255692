@import 'src/assets/styles/templates.scss';

.createPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px;
}

.content{
  height: 100%;
}

.btns {
  margin-top: 40px;

  @include flex-gap(20px);
  align-items: center;
}