@import 'src/assets/styles/templates.scss';
@import 'src/assets/styles/table.scss';

.table {
	min-width: 1020px;
}

.row {
	@extend %row;
	grid-template-columns: minmax(68px, 11%) 3fr 2fr 12% 15% 10% 170px 120px;
}

.switchWrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.wrapper {
	@include flex-col-gap(20px);

	height: 100%;
	padding: 40px;
}

.btns {
	@include flex-gap(20px);
	align-items: center;
}

.content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
}

.form {
	@include flex-col-gap(20px);
	justify-content: space-between;
	height: 100%;
}

.delete {
	width: 48px;
	height: 48px;
	margin-left: auto;
	padding: 0;
	border-width: 2px;

	svg {
		width: 24px;
		height: 24px;
	}

	svg path {
		fill: var(--c_red);
	}

	&:hover svg path {
		fill: var(--c_white-all);;
	}
}
