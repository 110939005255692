@import 'src/assets/styles/templates';

.historyPage {
  @include flex-col-gap(20px);

  height: 100%;
  padding: 40px;
}

// page content
.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.content {
  @extend %section;
  display: flex;
  flex-direction: column;
  gap: 24px;

  height: max-content;

  border: 1px solid var(--c_light);

  // main section
  .mainSection {
    @include flex-col-gap(24px);

    .subtitle {
      color: var(--c_grey);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .title {
      color: var(--c_black);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    .list {
      @include flex-col-gap(24px);
    }
  }
}

.emptyColor * {
  background-color: var(--c_white-all) !important;
}

.btns {
  display: flex;
  gap: 20px;
  margin-top: auto;
}

.approveSelect {
  margin-left: auto;
  width: 260px;
}


// secondary section
.spheresList {
  display: none;

  // показывать secondary часть если в ней есть элементы
  &:has(.sphereItem .sphereBlockList .blockItemList:not(:empty)) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .sphereItem {
    display: none;

    &:has(.sphereBlockList .blockItemList:not(:empty)) {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }


    .title {
      color: var(--c_black);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    .sphereBlockList {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .blockItemList {
        display: flex;
        flex-direction: column;
        gap: 24px;

        padding-bottom: 12px;
        border-bottom: 1px solid var(--c_light);

        // если нет history item
        &:empty {
          display: none;
        }
      }
    }

    .removeBlock{
      background: var(--c_danger-unactive);
    }

    .addBlock{
      background: var(--c_blue-uncative);
    }
  }
}