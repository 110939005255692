@import 'assets/styles/templates.scss';

.popover {
  @include flex-col-gap(16px);
  position: relative;

  padding: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .title {
      color: var(--c_black);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    svg {
      width: 14px;
      height: 14px;

      cursor: pointer;

      path {
        fill: var(--c_blue);
      }
    }
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  cursor: pointer;
}

.grey path {
  fill: var(--c_grey);
}

.blue path {
  fill: var(--c_blue);
}
