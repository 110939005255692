@import "src/assets/styles/templates";

.wrapper {
  padding: 20px;
  height: max-content;

  border-radius: 5px;
  border: 1px solid var(--c_light);
  background: var(--c_white-all);
}

.block {
  @include flex-col-gap(24px);

  max-width: 775px;

  .blockTitle {
    color: var(--c_black);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  .list{
    @include flex-col-gap(12px);
  }

  .addBtn {
    width: max-content;
  }
}

// read item
.item {
  @include flex-gap(24px);
  justify-content: space-between;
  align-items: center;

  padding: 16px;

  border-radius: 8px;
  border: 1px solid var(--c_grey);
  background: var(--c_white-all);

  .title {
    color: var(--c_black);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  .btns {
    @include flex-gap(24px);

    button {
      background: transparent;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

// from item
.formItem {
  @include flex-col-gap(24px);

  .btns {
    @include flex-gap(10px);
  }
}