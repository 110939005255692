@import 'src/assets/styles/templates';

.container {
  @include flex-col-gap(18px);
}

.top {
  @include flex-col-gap(8px);

  .label {
    color: var(--c_black);
    line-height: 20px;
  }
}

.group {
  @include flex-gap(10px);

  .buttonAdd {
    padding: 13px 15px;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      svg path {
        fill: var(--c_blue);
      }
    }

    svg path {
      fill: var(--c_white-text);
    }
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.item {
  @include flex-gap(12px);
  align-items: center;

  padding: 14px 20px;
  border-radius: 8px;
  background: var(--c_light);

  span{
    word-wrap: anywhere;
  }

  button {
    display: flex;
    align-items: center;
    cursor: pointer;

    background: transparent;
    color: var(--c_black);
    line-height: 20px;

    svg {
      height: 20px;
      width: 20px;

      path{
        color: var(--c_grey);
      }
    }
  }
}
