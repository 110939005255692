@import 'src/assets/styles/templates.scss';


.item {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  word-wrap: anywhere;
  span {
    color: var(--c_grey);
  }
  a {
    color: var(--c_blue);
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.link {
  padding: 8px 16px;
  color: var(--c_blue);
  background-color: var(--c_blue-uncative);
  width: fit-content;
  border-radius: 5px;
}

.content {
  @extend %section;
  display: flex;
  flex-direction: column;
  gap: 24px;

  height: max-content;

  border: 1px solid var(--c_light);

  // main section
  .mainSection {
    @include flex-col-gap(24px);

    .subtitle {
      color: var(--c_grey);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .title {
      color: var(--c_black);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    .list {
      @include flex-col-gap(24px);
    }
  }
}