@import "src/assets/styles/templates";

.wrapper {
  @include flex-col-gap(20px);
}

.list{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.item {
  display: inline-flex;
  align-items: center;
  gap: 12px;

  padding: 14px 20px;
  border-radius: 8px;
  background: var(--light, #F2F2F2);

  button{
    background: transparent;
    cursor: pointer;
  }
}