@import 'src/assets/styles/templates';

.wrapper {
  @include flex-col-gap(8px);
  position: relative;
  width: 100%;
}

.label {
  color: var(--c_black);
  font-size: 14px;
  line-height: 20px;
}

.disabled {
  opacity: .5;
  pointer-events: none;
}

.datePickerWrapper {
  position: relative;
  width: 100%;

  input {
    margin-bottom: 0;
  }

  div {
    margin-bottom: 0;
  }

  & > div:last-child {
    // margin-top: -12px;

    @media only screen and (max-width: $m_phone) {
      margin-top: 5px;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.textfield {
  width: 100%;
  height: 48px;

  color: var(--c_black);
  border: 1px solid var(--c_grey);
  background-color: var(--c_white-all);
  border-radius: $br_default;
  padding-left: 15px;

  font-size: 14px;
  font-weight: 400;

  &::placeholder {
    color: var(--c_dark);
  }

  &:not(:placeholder-shown) {
    border-color: var(--c_dark) !important;
    color: var(--c_black);
  }

  &:focus {
    border-color: var(--c_blue) !important;
  }


}