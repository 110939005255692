.title {
	color: #8692A6;
	margin-bottom: 20px;
}

.addButton {
	width: 280px;
}

.previewQuestions {
  width: 60%;
  padding: 20px;
  border: 1px dashed #B4B4B9;
  border-radius: 8px;
  margin-right: 40px;
  overflow-y: scroll;
}

.headerQuestion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.list {
  display: flex;
  flex-direction: column;
	width: 60%;
	margin: 0;
  li {
    padding: 15px 20px;
    border: 1px dashed #B4B4B9;
    border-radius: 8px;
    margin-bottom: 20px;
  }
}

.buttonIcon {
  cursor: pointer;
  margin: 0 5px;
  &:hover * {
    opacity: .8;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #2F2F2F;
  z-index: 1;
}