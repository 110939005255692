@import 'src/assets/styles/templates';

.container {
  display: flex;
  flex-direction: column;

  width: 595px;

  padding: 20px;
}
.title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 8px;
}
.subtitle {
  font-size: 16px;
  color: var(--c_dark);
  margin: 0;
}
.modalButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  margin-top: 40px;
 }