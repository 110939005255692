.button {
  position: relative;
}

.text {
  color: inherit;
}

.hideText {
  opacity: 0;
}

.loader, .loader:before, .loader:after {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}

.loader {
  color: #FFF;
  position: absolute;
  top: -15px;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -20px;
  animation-delay: -0.32s;
}

.loader:after {
  left: 20px;
}

@keyframes bblFadInOut {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em
  }
  40% {
    box-shadow: 0 2.5em 0 0
  }
}
