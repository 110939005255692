@import 'src/assets/styles/templates.scss';

.item {
  @extend %section;
  flex-direction: row;
  gap: 20px;

  max-width: 600px;
  width: 100%;

  border: $b_border;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .imageWrapper {
    height: 130px;
    width: 190px;
    flex-shrink: 0;

    svg {
      display: block;
      margin: auto;
    }
  }
  .image {
    display: block;

    max-height: 130px;
    max-width: 190px;
    object-fit: contain;

    margin: auto;
  }
  .content {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
  }
  .title {
    font-size: 16px;
    word-wrap: anywhere;

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .description {
    margin-top: 8px;
    color: var(--c_dark);
    word-wrap: anywhere;

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .btns {
    @include flex-gap(12px);
    align-items: center;
    width: 100%;
    margin-top: auto;
  }
  .edit {
    height: 36px;
    width: 100%;
  }
  .delete {
    padding: 8px;
    background-color: var(--c_red-uncative);
    border: none;

    svg path {
      fill: var(--c_red);
    }
  }
}
