* {
	color: var(--c_black);
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	border: none;
	outline: none;
	text-decoration: none;
	vertical-align: baseline;
}

a:hover {
	text-decoration: none;
}

// scroll Opera and Chrome
::-webkit-scrollbar-track {
	background-color: var(--c_light); /*Цвет скролла*/
}

::-webkit-scrollbar-thumb {
	border-radius: 5px; /*Закругление ползунка*/
	background-color: var(--c_grey); /*Цвет ползунка*/
}

::-webkit-scrollbar {
	width: 10px; /*Толщина скролла*/
}

// scroll FireFox
* {
	scrollbar-color: var(--c_grey) var(--c_light); /*Цвет скролла, цвет ползунка*/
	scrollbar-width: thin; /*Толщина скролла thin, auto*/
}

#root {
	display: flex;
	flex-direction: column;
}

html,
body,
main,
nav,
#root {
	height: 100%;
}

body {
	background-color: #fff;
	overflow-x: hidden;
	color: var(--color-black);
}

main {
	display: flex;
	flex: 1;
	height: fit-content;
	padding-left: 230px;

	background-image: url('../icons/patterns/pt_background.svg');
}

section {
	width: 100%;
	height: 100%;
}

.not-found {
	display: flex;
	justify-content: center;
	font-size: 28px;
	font-weight: 600;
	margin-top: 80px;
}

.modal-active {
	overflow: hidden;
}

.form-row {
	flex-direction: row-reverse;
	margin-bottom: 20px;
}

.edit-icon-block {
	cursor: pointer;

	transition: all 0.15s ease-in-out;

	span {
		color: var(--c_blue);
		font-size: 16px;
	}

	&:hover {
		opacity: 0.5;
	}
}

.wrap-text {
	word-wrap: anywhere;
}

.limit-line-text {
	display: -moz-box;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	box-orient: vertical;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	line-clamp: 3;
	-webkit-line-clamp: 3;
}

.react-form-builder .react-form-builder-preview .edit-form {
	left: initial;
	right: -1000px;
}

.react-form-builder .react-form-builder-preview.is-editing .edit-form {
	left: initial;
	right: 0;
}

@keyframes edit {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}

@keyframes trash {
	0% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(30deg);
	}
	50% {
		transform: rotate(0deg);
	}
	75% {
		transform: rotate(-30deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
