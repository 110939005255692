@import 'src/assets/styles/templates.scss';

.fileUpload {
  input {
    display: none;
  }
}
.label {
  display: block;
  line-height: 20px;
  margin-bottom: 8px;
}
.uploadBtn {
  @include flex-gap(10px);
  align-items: center;

  padding: 14px 20px;

  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='rgb(255, 255, 255)' stroke='gray' stroke-width='1' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: $br_default;

  transition: 0.15s ease-in-out;

  span {
    transition: 0.1s ease-in-out;
    color: var(--c_dark);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  svg {
    max-width: 20px;
    width: 100%;
    height: 20px;
  }

  &:focus,
  &:hover,
  &:active {
    span {
      color: var(--c_blue);
    }
  }
}
.description {
  color: var(--c_grey);
  margin-top: 8px;
}
.error {
  .uploadBtn {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='crimson' stroke-width='1' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
  span {
    color: var(--c_danger);
  }

  .description {
    color: var(--c_danger);
  }
}