.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  overflow-x: hidden;
  z-index: 999;

  &:after {
    content: '';
    position: absolute;
    left: 260px;
    top: 62px;
    display: block;
    width: calc(100vw - 260px);
    height: calc(100vh - 62px);
    background-color: rgba(89, 142, 189, 0.575);
  }
}

.modal {
  position: relative;
  width: calc(100vw - 260px);
  min-height: 160px;
  left: 260px;
  top: 62px;
  padding: 60px 60px 30px;
  background-color: #F7F8FD;
  z-index: 100;
  overflow-y: visible;

  .content {
    display: flex;
    flex-direction: column;
  
    .header {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 30px;
    }
  }

  .close {
    position: absolute !important;
    right: 60px;
    top: 60px;
  }
}