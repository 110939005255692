
.cardsPage {
  display: flex;
  flex-direction: column;
  margin: 0 40px 50px 40px;
}
.list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  margin-top: 40px;
}
.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  margin-top: 40px;

  h5 {
    font-size: 24px;
  }
  span {
    color: var(--c_grey);
  }
}