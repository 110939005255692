.column {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	gap: 8px;

	padding: 0 10px;

	height: 100%;
}

.condition{
	width: 130px;
}

.label {
	font-size: 16px;
	font-weight: 500;
	word-break: break-word;
}

.sorting {
	cursor: pointer;
	position: relative;
	padding-left: 24px;

	&::before {
		content: '';
		display: block;
		width: 16px;
		height: 16px;

		position: absolute;
		left: 0;
		top: 1px;

		transition: all 0.25s ease-out 0s;

		background-image: url('../../../../assets/icons/general/ic_arrow-down.svg');
	}

	&.sortingAsc::before {
		filter: invert(42%) sepia(86%) saturate(885%) hue-rotate(177deg) brightness(84%) contrast(91%);
	}

	&.sortingDesc::before {
		transform: rotate(180deg);
		filter: invert(42%) sepia(86%) saturate(885%) hue-rotate(177deg) brightness(84%) contrast(91%);
	}
}

.sortingAsc, .sortingDesc {
	color: var(--c_blue);
}

.dateRange {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 4px;

	span {
		color: var(--black, #272937);
		line-height: 20px;
	}
}
.contentCell {
	grid-column: 7/9;
}
.content {
	height: 48px;
	font-size: 16px;
	font-weight: 500;
	display: flex;
	align-items: center;
	span {
		color: #7A7A7A;
		max-width: 150px !important;
		text-wrap: wrap;
		word-break: break-word;
	}
		color: #272937;
}
