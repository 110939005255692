@import 'src/assets/styles/templates.scss';

.friendWork {
  @include flex-gap(8px);
  align-items: center;

  margin-left: auto;

  svg {
    cursor: pointer;
  }
}

.icSync {
  cursor: pointer;
}

.hidden {
  display: none;
  pointer-events: none;
}


.modal {
  .container {
    display: flex;
    flex-direction: column;

    width: 575px;

    padding: 20px;
  }
  .title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 8px;
  }
  .subtitle {
    font-size: 16px;
    color: var(--c_dark);
    margin: 0;
  }
  .modalButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    margin-top: 40px;
  }
}

button {
  svg path {
    transition: .15s ease-in-out;
  }

  &:hover svg path {
    fill: #1362FF;
  }
}

.friendWorkButton{
  margin-left: auto;
}