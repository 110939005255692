@import 'src/assets/styles/templates.scss';

.updatePage {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px;
}

.form {
  @include flex-col-gap(20px);
  justify-content: space-between;
  height: 100%;
}
.btns {
  @include flex-gap(20px);
  align-items: center;
}
.fields {

}
.delete {
  width: 48px;
  height: 48px;
  margin-left: auto;
  padding: 0;
  border-width: 2px;

  svg {
    width: 24px;
    height: 24px;
  }
  svg path {
    fill: var(--c_red);
  }

  &:hover svg path {
    fill: var(--c_white-all);;
  }
}

