@import 'src/assets/styles/templates.scss';

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 40px;
  column-gap: 20px;

  color: var(--c_white-text);
  border: $b_border;
  border-radius: $br_default;
  background-color: var(--c_white-all);

  padding: 20px;
}
.item {
  align-self: flex-start;
  word-break: break-all;
}

.disabled {
  opacity: .75;
  pointer-events: none;
}

.fullWidth {
  grid-column: 1/3;
}