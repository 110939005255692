@import 'src/assets/styles/templates.scss';
@import 'src/assets/styles/table.scss';

.table {
	min-width: 500px;
}


.row {
  @extend %row;
  grid-template-columns: minmax(68px, 11%) 1fr 120px 120px;
}

.switchWrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
