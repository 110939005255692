@import 'src/assets/styles/templates';

.App {
  font-family: sans-serif;
  text-align: center;
}

.body {
  width: 100%;
}

.submit {
  
}

.header {
  width: 100%;
  margin-bottom: 55px;
}

.headerTitle {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 24px;
}

.breadCrumbs {

}

.breadCrumbs {
  font-weight: 500;
  font-size: 16px;
}

.formBuilder {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.body {
  display: flex;
  flex-wrap: nowrap;
  height: calc(100vh - 385px);
  margin-bottom: 40px;
  overflow: hidden;
}

.previewQuestions {
  width: 60%;
  padding: 20px;
  border: 1px solid var(--c_grey);
  border-radius: $br-default;
  margin-right: 40px;
  overflow-y: scroll;
  background-color: var(--c_white-all);
}

.emptyList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-weight: 700;
  font-size: 22px;
  span {
    font-weight: 500;
    font-size: 16px;
    color: var(--c_blue-unactive);
  }
}

.title {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
}

.selectQuestions {
  width: 40%;
  border: $b_border;
  padding: 20px;
  background-color: var(--c_white-all);
  border-radius: $br_default;
}

.listQuestions {
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 40px;
  background-color: var(--c_white-all);

  li {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px dashed var(--c_dark);
    border-radius: 8px;
    padding: 15px;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    cursor: pointer;

    span {
      margin-left: 10px;
      color: var(--c_dark);
    }
  }
}

.headerQuestion {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  font-size: 16px;
  font-weight: 500;
}

.list {
  display: flex;
  flex-direction: column;
  li {
    padding: 15px 20px;
    border: 1px dashed var(--c_grey);
    border-radius: 8px;
    margin-bottom: 20px;
  }
}

.createQuestions {

}

.btns {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: auto;
}

.btnsEdit {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: 20px;
}

.saveButton {
  width: 135px;
  height: 52px;
  background: var(--c_blue);
  border-radius: 8px;
  color: #fff;
  margin-right: 20px;
}

.cancelButton {
  width: 135px;
  height: 52px;
  background: #fff;
  border-radius: 8px;
  color: #5D5F69;
  border: 2px solid #5D5F69;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #2F2F2F;
  z-index: 1;
}

.editQuestion {
  width: 40%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  padding: 40px 20px;
  overflow: scroll;
  z-index: 2;
}

.editTitle {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 40px;
}
.answerTitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.reportTitle {
  display: flex;
  flex-direction: column;
  gap: 8px;

  font-weight: 700;
  font-size: 24px;
  margin-bottom: 40px;

  span {
    color: var(--c_dark);
    font-weight: 400;
  }
}

.reportCheckBox {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.question {
  margin-bottom: 40px;
}

.answers {
  height: calc(100vh - 310px);
  overflow: hidden;
  overflow-y: scroll;
}

.answer {
  margin-bottom: 32px;
}

.isLink {
  margin: 8px 0;
  display: flex;
  align-items: center;
}

.answerInput {
  position: relative;
}

.textInputIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.formGroup {
  @include flex-col-gap(10px);
  margin-top: 40px;
}

.select {
  margin-bottom: 10px;
}

.selectRule {
  width: 150px;
  margin-bottom: 10px;
}


.buttonIcon {
  cursor: pointer;
  margin: 0 10px;
  &:hover * {
    opacity: .8;
  }
}

// tabs
.tabs {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.tab {
  font-weight: 500;
  font-size: 16px;
  margin-right: 40px;
  cursor: pointer;
  padding-bottom: 15px;
  color: var(--c_dark);
}

.tabActive {
  color: var(--c_blue);
  border-bottom: 2px solid var(--c_blue);
  opacity: 1;
}

.formList {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.formCard {
  @extend %flex-col;
  align-items: flex-start;

  width: 390px;

  padding: 20px;

  border: $b_border;
  border-radius: $br_default;
  cursor: pointer;
  background-color: var(--c_white-all);

  transition: .15s ease-in-out;

  &:hover {
    border-color: var(--c_blue);
  }
}

.formCardTitle {
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
}

.answersCount {
  font-size: 14px;
  color: var(--c_blue);
  margin-top: 8px;
}
.inactive {
  color: var(--c_grey);
}

.date {
  color: var(--c_dark);
  margin-top: 20px;
}

.addField {
  padding: 15px 20px;
  color: var(--c_grey);
  border: 1px solid var(--c_grey);
  border-radius: $br_default;
  background: var(--c_white-all);
  cursor: pointer;
}

.settingsForm {
  @extend %flex-col;

  width: 60%;
  height: calc(100vh - 345px);
  border: $b_border;
  border-radius: $br_default;

  padding: 20px;

  background-color: var(--c_white-all);
}

.fieldSetting {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.settingsFormTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--c_blue-unactive);
  margin-bottom: 20px;
}

.fieldTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--c_dark);
  margin-bottom: 8px;
}

.textAreaSetting {
  height: 100px;
}

.field {
  width: 100%;
  margin-top: 40px;
}

.decrement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  border: 1px solid #8692a6;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: rgb(245, 249, 255);
}