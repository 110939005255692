@import "src/assets/styles/templates";

.wrapper {
  @include flex-col-gap(8px);
  align-items: flex-start;
  overflow: hidden;
  height: min-content;
}

.label {
  color: var(--c_black);
  font-size: 14px;
  line-height: 20px;
}

.mapWrapper {
  @include flex-col-gap(0);

  overflow: hidden;
  position: relative;

  border-radius: 8px;
  border: 1px solid var(--c_grey);

  width: 100%;
  height: 720px;

  .header {
    @include flex-gap(20px);
    align-items: center;

    padding: 14px 20px;

    border-bottom: 1px solid var(--c_grey);

    .item {
      @include flex-gap(8px);
      align-items: center;
    }
  }

  .map {
    height: 100%;
    overflow: hidden;

    .mapDraggable {
      position: relative;

      width: 3675px;
      height: 2153px;

      .image {
        width: 100%;
        height: 100%;
      }

      .mapLayer {
        position: absolute;

        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        z-index: 1;
      }
    }
  }

  .resizeButtons {
    @include flex-col-gap(0);

    overflow: hidden;
    position: absolute;

    right: 20px;
    top: 50%;

    border-radius: 6px;
    border: 1px solid var(--grey-light);

    transform: translate(0, -50%);

    button {
      width: 38px;
      height: 45px;

      cursor: pointer;
      background: var(--c_light);

      svg path {
        fill: var(--grey-default);
        transition: all 0.15s ease-in 0s;
      }

      &:hover {
        svg path {
          fill: var(--grey-dark);
        }
      }
    }

    .disabled {
      cursor: default;

      svg path {
        fill: var(--grey-light);
      }

      &:hover svg path {
        fill: var(--grey-light);
      }
    }
  }
}



