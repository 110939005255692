@import 'src/assets/styles/table';

.tableHeader {
	@include flex-col-gap(8px);
	padding: 20px 0;
}

.row {
	min-height: min-content !important;
}
