@import "src/assets/styles/templates";

.wrapper {
	@include flex-col-gap(8px);
	position: relative;
	width: 100%;
}

.label {
	color: var(--c_black);
	font-size: 14px;
	line-height: 20px;
}

.inputWrapper {
	display: flex;
}

.prefixWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 0 auto;

	padding: 0 12px;
	background: #F2F2F2;

	border: 1px solid #B4B4B9;
	border-right: none;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;

	.prefix {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		color: #5D5F69;
	}
}

.input {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
