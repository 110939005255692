@import 'src/assets/styles/templates.scss';
@import 'src/assets/styles/table.scss';

.table {
	min-width: 400px;
}

.row {
	@extend %row;
	grid-template-columns: 1fr 120px;
}
