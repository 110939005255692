@import 'src/assets/styles/templates.scss';

.switch {
	display: inline-block;
	width: max-content;
  position: relative;
  cursor: pointer;

  input {
    display: none;
  }

  .checkMark {
    position: relative;
    display: block;
    width: 36px;
    height: 24px;
    border-radius: 12px;
    transition: all .25s ease-in-out;
    background-color: var(--c_grey);

    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background-color: var(--c_white-all);
      border-radius: 50%;

      position: absolute;
      top: 4px;
      left: 4px;

      transition: all .25s ease-in-out;
    }
  }

  input:checked + .checkMark {
    background-color: var(--c_blue);

    &::before {
      transform: translateX(calc(100% - 4px));
    }
  }
}

.disabled {
  opacity: .5;
  pointer-events: none;
}

.label {
  display: block;
  line-height: 20px;
  margin-bottom: 8px;
	width: max-content;
}
