@import 'src/assets/styles/templates.scss';
@import 'src/assets/styles/table.scss';

.table {
	min-width: 680px;
}

.row {
	@extend %row;
	grid-template-columns: minmax(68px, 11%) 1fr 21% 21% 120px;
}

.approved {
	color: var(--c_blue);
}

.notApproved {
	color: var(--c_red);
}
