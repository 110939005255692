#editorjs {
  margin: 15px 0;

  max-width: 920px;
  width: 100%;

  padding-left: 90px;
  padding-right: 20px;

  * {
    word-break: break-word;
  }

  a, a * {
    color: #1362FF;
    text-decoration: underline;
  }
}


// content width
.ce-block__content {
  max-width: 680px;
  width: 100%;
}

.ce-toolbar__actions {
  padding-right: 20px;
}


// color in tooltips
.ct__content * {
  color: var(--c_light) !important;
}


// editor layout
.codex-editor__redactor {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.cdx-list__item, .ce-paragraph, .image-tool, .cdx-list, .cdx-quote {
  padding: 0 !important;
}


// header
//remove padding first title
.codex-editor__redactor > :first-child .ce-block__content .ce-header {
  padding-top: 0 !important;
}

h3.ce-header {
  padding: 36px 0 0 0 !important;
}

h3.ce-header, h3.ce-header * {
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  letter-spacing: -0.03em;
}


// list
.cdx-list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-left: 24px !important;
}


// image
.image-tool__image-picture {
  max-width: 100%;
  max-height: 376px;
  width: 100%;
  height: 100%;

  border-radius: 12px;
  object-fit: cover;
}

// disabled stretched option
.ce-block--stretched .ce-block__content {
  max-width: 680px !important;
}

// disabled border option
.image-tool--withBorder .image-tool__image {
  border: none !important;
}

// disabled background option
.image-tool--withBackground .image-tool__image {
  padding: 0 !important;
  background: none !important;

  .image-tool__image-picture {
    max-width: 100% !important;
  }
}


// paragraph, list item, input
.ce-paragraph, .cdx-list__item, .cdx-input {
  &, * {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.006em;
  }
}


@media screen and (max-width: 1000px) {
  #editorjs {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ce-toolbar__actions {
    padding-right: 0;
    padding-left: 20px;
  }
}