@import 'src/assets/styles/templates.scss';

.tableWrapper {
  border: $b_border;
  border-radius: $br_default;
  overflow-x: scroll;

  width: calc(100vw - 230px - 80px);

  margin-top: 40px;

  background-color: var(--c_white-all);
}

.table {
  //min-width: 1400px;
  min-height: 700px;
}

.tableHeader,
.tableBody {
  width: 100%;
  // width: max-content;
}

.listPage {
  display: flex;
  flex-direction: column;
  margin: 0 40px;
  margin-bottom: 50px;
}

.loaderContainer {
  display: flex;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 60px;
  color: var(--c_white-text);
}

.emptyContainer {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: var(--c_grey);
    text-align: center;

    margin-bottom: 20px;
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: var(--c_dark);

    display: flex;
    margin: auto;
  }
}
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
}
