@import 'src/assets/styles/templates';

.approvePage {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px;
}
.info {
  @extend %section;
  flex-direction: row;
  gap: 60px;
  border: $b_border;
}
.main {
  @include flex-col-gap(24px);

  width: calc(50% - 60px);

  &::before {
    content: 'Основная информация';
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;
  }
}
.secondary {
  @include flex-col-gap(12px);
  margin-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--c_light);
}
.secondaryBlock {
  @include flex-col-gap(12px);
  width: calc(50% - 60px);

  b {
    font-size: 16px;
    font-weight: 500;
  }
  b:not(:first-child) {
    margin-top: 12px;
  }
}
.item {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  word-wrap: anywhere;

  span {
    color: var(--c_grey);
  }

  a{
    color: var(--c_blue);
  }
}
.btns {
  display: flex;
  gap: 20px;
  margin-top: auto;
}
.approveSelect {
  margin-left: auto;
  width: 260px;
}
