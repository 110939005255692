@import 'src/assets/styles/templates.scss';

.createPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 40px;
}
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0;
}
.form {
  @include flex-col-gap(20px);
  justify-content: space-between;
  height: 100%;
}
.btns {
  @include flex-gap(20px);
  align-items: center;
}
.delete {
  margin-left: auto;
  padding: 11px 13px;
  border-color: var(--c_grey);

  svg path {
    fill: var(--c_grey);
  }
}
.container {
  @extend %flex-col;
  width: 820px;
  padding: 30px 20px;
}
.modalBtns {
  @include flex-gap(30px);
  align-items: center;
  justify-content: flex-end;

  margin-top: 30px;
}