.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.delete {
  width: 48px;
  height: 48px;
  margin-left: auto;
  padding: 0;
  border-width: 2px;

  svg {
    width: 24px;
    height: 24px;
  }

  svg path {
    fill: var(--c_red);
  }

  &:hover svg path {
    fill: var(--c_white-all);;
  }
}
