@import "src/assets/styles/templates";

.mark {
  width: 16px;
  height: 16px;

  border-radius: 50%;

  background: white;
  border: 3px solid transparent;
}

.red {
  border-color: #FF3A30;
}

.black {
  border-color: #272937;
}